export const environment = {
  "production": false,
  "isTest": true,
  "CANDY_BACKEND_URL": "https://gaz-esale-admin.gralinte.com",
  "CANDY_REST_URL": "https://gaz-esale-admin.gralinte.com/api/rest/1.0.0",
  "SENTRY_ENABLED": true,
  "SENTRY_DSN": "https://d298bd68ed1d4295bf88e89d59024971@sentry.shpundo.com/5",
  "SENTRY_ENVIRONMENT": "sandbox",
  "SENTRY_RELEASE_PREFIX": "gaz-connect-mobile-dealer",
  "CANDY_REALM": "gaz-dealer-mobile-sandbox"
};